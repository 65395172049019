<template>
  <div>
    <!-- desktop navbar -->
    <div v-if="$device.isDesktop" class="layout layout-nav-top layout-sidebar" id="desktopDiv">
      <div class="navbar navbar-expand-lg bg-dark navbar-dark sticky-top">
        <nuxt-link to="/" class="navbar-brand">
          <img alt="MakeYourStats logo" title="MakeYourStats logo" width="30" height="30" src="/makeyourstats-logo.png">
        </nuxt-link>
        <div class="collapse navbar-collapse justify-content-between">
          <ul class="navbar-nav">
            <li class="nav-item pt-1">
              <nuxt-link to="/" class="nav-link">
                Fixtures
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/quick-stats" class="nav-link">
                Quick stats
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/account/feeds" class="nav-link">
                Feeds
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/tracking" class="nav-link">
                Tracking
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/backtesting" class="nav-link">
                Backtesting
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <a href="https://makeyourstats.tawk.help/" target="_blank" class="nav-link js-scroll-trigger text-warning">
                Guide
              </a>
            </li>
            <li class="nav-item pt-1">
              <div class="dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="nav-dropdown-2">More</a>
                <div class="dropdown-menu bg-dark">
                  <nuxt-link to="/leagues" class="dropdown-item text-white">
                    Leagues
                  </nuxt-link>
                  <nuxt-link to="/stats/btts" class="dropdown-item text-white">
                    Stats rankings
                  </nuxt-link>
                  <a href="/pro" v-if="!$isInReview" class="dropdown-item text-white">
                    PRO
                  </a>
                  <nuxt-link to="/earn" v-if="!$isInReview" class="dropdown-item text-white">
                    Earn PRO
                  </nuxt-link>
                  <a href="https://makeyourstats.kampsite.co" class="dropdown-item text-white" target="_blank">
                    Vote new features
                  </a>
                  <nuxt-link to="/updates" class="dropdown-item text-white">
                    Updates
                  </nuxt-link>
                  <a href="/blog" class="dropdown-item text-white">
                    Blog
                  </a>
                  <nuxt-link to="/contact" class="dropdown-item text-white">
                    Contact
                  </nuxt-link>
                  <nuxt-link to="/legal" class="dropdown-item text-white">
                    Legal
                  </nuxt-link>
                </div>
              </div>
            </li>
            <li class="nav-item ml-2 pt-1">
              <search v-if="$route.name === 'index' || $route.name === 'v1'"/>
            </li>
          </ul>
          <ul class="nav navbar-nav flex-row justify-content-md-center justify-content-start flex-nowrap">
            <li v-if="$route.name !== 'account' && !hasPlan && isLoggedIn" class='nav-item'>
              <a @click="gtag('event', 'click_menu', { menu: 'pro' })" href='/pro' class='nav-link js-scroll-trigger bg-primary text-white rounded pl-1'>
                Try PRO
              </a>
            </li>
            <li v-if="isLoggedIn" class="nav-item">
              <nuxt-link to="/account" class="nav-link">
                My account
              </nuxt-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <nuxt-link to="/login" class="nav-link">
                Login
              </nuxt-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <nuxt-link to='/register' class='nav-link js-scroll-trigger bg-primary text-white rounded px-1'>
                Create account
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- app content -->
      <nuxt />
    </div>

    <!-- mobile navbar -->
    <div v-if="$device.isMobileOrTablet" class="layout layout-nav-top layout-sidebar">
      <!-- do not show navbar in app -->
      <div v-if="!$isApp" class="navbar navbar-expand-lg bg-dark navbar-dark sticky-top px-1" id='navbar-mobile' style="z-index: 850">
        <div class="d-flex align-items-center w-100">
          <nuxt-link to="/" class="navbar-brand d-flex mr-1 pt-2">
            <img width="30" height="30" class="mr-2" alt="MakeYourStats logo" title="MakeYourStats logo" src="/makeyourstats-logo.png" />
            <span v-if="$route.name !== 'index' && $route.name !== 'v1'" class='align-self-center text-white'>
              Make<span class="text-primary">Your</span>Stats
            </span>
          </nuxt-link>
          <!-- search -->
          <search v-if="$route.name === 'index' || $route.name === 'v1'"/>
          <button class="navbar-toggler ml-auto"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-collapse"
            aria-controls="navbar-collapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon" />
          </button>
        </div>
        <div class="collapse navbar-collapse justify-content-between" id="navbar-collapse" ref="navbarCollapse">
          <ul class="navbar-nav ml-1">
            <li class="nav-item">
              <nuxt-link to="/" @click.native="$refs.navbarCollapse.classList.remove('show')" class="nav-link active">
                Fixtures
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/quick-stats" @click.native="$refs.navbarCollapse.classList.remove('show')" class="nav-link">
                Quick stats
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/account/feeds" @click.native="isLoggedIn ? $refs.navbarCollapse.classList.remove('show') : null" class="nav-link">
                Feeds
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/tracking" @click.native="$refs.navbarCollapse.classList.remove('show')" class="nav-link">
                Tracking
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <nuxt-link to="/backtesting" @click.native="$refs.navbarCollapse.classList.remove('show')" class="nav-link">
                Backtesting
              </nuxt-link>
            </li>
            <li class="nav-item pt-1">
              <a href="https://makeyourstats.tawk.help/" target="_blank" class="nav-link js-scroll-trigger text-warning">
                Guide
              </a>
            </li>
            <li class="nav-item pt-1">
              <div class="dropdown">
                <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" id="nav-dropdown-2">More</a>
                <div class="dropdown-menu bg-dark border-0">
                  <nuxt-link to="/leagues" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Leagues
                  </nuxt-link>
                  <nuxt-link to="/stats/btts" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Stats rankings
                  </nuxt-link>
                  <a href="/pro" v-if="!$isInReview" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    PRO
                  </a>
                  <nuxt-link to="/earn" v-if="!$isInReview" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Earn PRO
                  </nuxt-link>
                  <a href="https://makeyourstats.kampsite.co" class="dropdown-item text-white" target="_blank">
                    Vote new features
                  </a>
                  <nuxt-link to="/updates" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Updates
                  </nuxt-link>
                  <a href="/blog" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Blog
                  </a>
                  <nuxt-link to="/contact" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Contact
                  </nuxt-link>
                  <nuxt-link to="/legal" @click.native="$refs.navbarCollapse.classList.remove('show')" class="dropdown-item text-white">
                    Legal
                  </nuxt-link>
                </div>
              </div>
            </li>
            <hr>
            <li v-if="$route.name !== 'account' && !hasPlan && isLoggedIn" class='nav-item'>
              <a href='/pro' @click.native="gtag('event', 'click_menu', { menu: 'pro' }); $refs.navbarCollapse.classList.remove('show')" class='nav-link js-scroll-trigger bg-primary text-white rounded px-1'>
                Try PRO
              </a>
            </li>
            <li v-if="isLoggedIn" class="nav-item">
              <nuxt-link to="/account" class="nav-link" @click.native="$refs.navbarCollapse.classList.remove('show')">
                My account
              </nuxt-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <nuxt-link to="/login" class="nav-link">
                Login
              </nuxt-link>
            </li>
            <li v-if="!isLoggedIn" class="nav-item">
              <nuxt-link to='/register' class='nav-link js-scroll-trigger bg-primary text-white rounded px-1'>
                Create account
              </nuxt-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- content -->
      <nuxt />

      <!-- app bottom navbar -->
      <div v-if="$isApp && (!sideUrls.includes($route.name) || $route.query.from_notification)" id="app-tabs" :class="{ 'bg-light position-fixed w-100 px-2 pt-1': true, 'pb-3': $device.isIos, 'pb-1': $device.isAndroid }">
        <div class="d-flex justify-content-between align-items-center h-100">
          <nuxt-link v-if="!$route.query.from_notification" to="/" :custom="true" class="decoration-none">
            <div :class="$route.name == 'index' && ($route.query.date || $route.query.fixture || $route.query.fixture_id || $route.query.ref || !Object.keys($route.query).length) ? 'text-center text-primary': 'text-center text-secondary'">
              <i class="fas fa-futbol mb-1"></i>
              <br>
              <a @click="clickMenu('fixtures')">Fixtures</a>
            </div>
          </nuxt-link>
          <a v-else @click="clickMenu('fixtures')" href="/" class="decoration-none">
            <div :class="$route.name == 'index' && ($route.query.date || $route.query.fixture || $route.query.fixture_id || !Object.keys($route.query).length) ? 'text-center text-primary': 'text-center text-secondary'">
              <i class="fas fa-futbol mb-1"></i>
              <br>
              Fixtures
            </div>
          </a>
          <nuxt-link v-if="!$route.query.from_notification" :to="`/?live=true`" :custom="true" class="decoration-none">
            <div :class="$route.fullPath.includes('?live=true') ? 'text-center live-color': 'text-center text-secondary'">
              <i class="fas fa-bolt mb-1"></i>
              <br/>
              <a @click="clickMenu('live')">Live</a>
            </div>
          </nuxt-link>
          <a v-else @click="clickMenu('live')" :to="`/?live=true`" class="decoration-none">
            <div :class="$route.fullPath.includes('live=true') ? 'text-center live-color': 'text-center text-secondary'">
              <i class="fas fa-bolt mb-1"></i>
              <br/>
              Live
            </div>
          </a>
          <nuxt-link v-if="!$route.query.from_notification" :to="isPro ? '/?mygames=true' : $route.fullPath" :custom="true" title="Account required" :data-toggle="!isPro ? 'tooltip' : null" :class="{ 'decoration-none': true, 'disabled': !isPro }">
            <div :class="$route.fullPath == '/?mygames=true' ? 'text-center text-primary': 'text-center text-secondary'">
              <i class="far fa-star mb-1"></i>
              <br/>
              <a @click="clickMenu('my-games')">My games</a>
            </div>
          </nuxt-link>
          <a v-else @click="clickMenu('my-games')" :href="isPro ? '/?mygames=true' : $route.fullPath" title="Account required" :data-toggle="!isPro ? 'tooltip' : null" :class="{ 'decoration-none': true, 'disabled': !isPro }">
            <div :class="$route.fullPath == '/?mygames=true' ? 'text-center text-primary': 'text-center text-secondary'">
              <i class="far fa-star mb-1"></i>
              <br/>
              My games
            </div>
          </a>
          <nuxt-link to="/quick-stats" :custom="true" class="decoration-none">
            <div :class="['quick-stats'].includes($route.name) ? 'text-center text-primary' : 'text-center text-secondary'">
              <i class="fas fa-chart-line mb-1"></i>
              <br/>
              <a @click="clickMenu('quick-stats')">Quick stats</a>
            </div>
          </nuxt-link>
          <nuxt-link to="/more" :custom="true" class="decoration-none">
            <div :class="['more'].includes($route.name) ? 'text-center text-primary' : 'text-center text-secondary'">
              <i class="fa fa-bars mb-1"></i>
              <br/>
              <a @click="clickMenu('more')">More</a>
            </div>
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import smoothscroll from 'smoothscroll-polyfill'
import insertUserDevice from '~/apollo/mutations/insertUserDevice'
import updateUserNotifications from '~/apollo/mutations/updateUserNotifications'

export default {
  components: {
    search: () => import('@/components/SearchBar.vue')
  },
  computed: {
    ...mapState({
      isPro: state => state.user_details.user_data.expire_at ? true : false,
      hasPlan: state => state.user_details.user_data.user_plan.filter(plan => plan.status == 'active').length ? true : false,
      isLoggedIn: state => state.user.username ? true : false
    }),
    sideUrls () {
      return ['account', 'account-subscription', 'contact', 'leagues', 'league-id', 'team-id', 'player-id', 'account-feeds', 'account-feeds-add', 'account-feeds-edit-id', 'notifications', 'fixture-id', 'tracking', 'backtesting', 'backtesting-v1', 'updates', 'stats-btts', 'stats-cards', 'stats-corners', 'stats-over05-fh-goals', 'stats-over15-goals', 'stats-over25-goals', 'stats-xg', 'stats-xg-leagues', 'league-id-stats-xg', 'earn', 'filters-explore', 'fixture-v1-id']
    }
  },
  async mounted() {
    await this.$store.dispatch('GET_USER_DETAILS')
    await this.$store.dispatch('GET_USER_NOTIFICATIONS')
    // analytics
    gtag('set', 'user_id', this.$store.state.user?.username || 'free')
    gtag('set', 'user_properties', {
      'pro': this.isPro,
      'app': this.$isApp
    })
    // used for smooth scroll on mobile
    smoothscroll.polyfill()
    // save refferal
    if (this.$route.query.ref) {
      if (!['makeyourstats', 'makeyourstats-menu', 'pricing'].includes(this.$route.query.ref)) {
        this.$cookies.set('ref', this.$route.query.ref, {
          path: '/',
          maxAge: 60 * 60 * 24 * 31
        })
      }
    }
    // app only
    if (this.$isApp && this.$route.name === 'index') {
      const info = { user: this.$store.state.user.username || 'free' };
      const json = JSON.stringify(info);
      setTimeout(() => {
        window.location.href = `gonative://registration/send?customData=${encodeURIComponent(json)}`
      }, 100)
    }
    // desktop notifications - only for desktop/mobile and pro users
    if (!(!this.$isApp && this.isPro)) {
      return
    }
    // https://documentation.onesignal.com/docs/troubleshooting-web-push
      // init OnseSignal SDK
    const OneSignal = window.OneSignal || [];
    OneSignal.push(() => {
      OneSignal.SERVICE_WORKER_PARAM = { scope: '/js/onesignal/' }
      OneSignal.SERVICE_WORKER_PATH = 'js/onesignal/OneSignalSDKWorker.js'
      OneSignal.SERVICE_WORKER_UPDATER_PATH = 'js/onesignal/OneSignalSDKUpdaterWorker.js'
      OneSignal.init({
        appId: process.env.ONE_SIGNAL_APP_ID,
        safari_web_id: process.env.ONE_SIGNAL_SAFARI_WEB_ID,
        notifyButton: {
          enable: true,
        },
        allowLocalhostAsSecureOrigin: true,
      })
    })
    // listen on subscription change on notifications
    OneSignal.push(() => {
      OneSignal.on('subscriptionChange', async (isSubscribed) => {
        OneSignal.getUserId(async (userId) => {
          // save user in our db
          const user_notifications = JSON.parse(JSON.stringify(this.$store.state.user_details.user_notifications))
          Object.keys(user_notifications).forEach((key) => {
            delete user_notifications[key].key
          })
          await this.$apollo.mutate({
            mutation: insertUserDevice,
            variables: {
              user: this.$store.state.user.username,
              onesignal_id: userId,
              onesignal_subscribed: isSubscribed,
              notifications_type: user_notifications
            },
          })
          .then(async () => {
            // register our id to onesignal id
            OneSignal.setExternalUserId(this.$store.state.user.username)
            // sync notifications
            await this.$apollo.mutate({
              mutation: updateUserNotifications,
              variables: {
                user: this.$store.state.user.username,
                notifications: user_notifications
              },
            })
            .then(() => {
              this.$toasted.success('Notifications updated', { position: 'top-center', duration: 2000 })
            })
            .catch( e => {
              this.$sentry.captureException(e, {
                tags: {
                  component: 'layout-app',
                  method: 'OneSignal.getUserId'
                },
              })
              this.$toasted.errorr('An error occured, notifications were not updated', { position: 'top-center', duration: 4000 })
            })
          })
          .catch((e) => {
            this.$sentry.captureException(e, {
              tags: {
                component: 'layout-app',
                method: 'OneSignal.getUserId1'
              },
            })
          })
        })
      })
    })
  },
  methods: {
    clickMenu (menu) {
      gtag('event', 'click_menu_app', {
        menu
      })
    }
  }
}
</script>

<style scoped>
  #app-tabs {
    bottom: 0px;
    left: 0px;
    z-index: 900;
    box-shadow: 0 -4px 12px 0 rgba(0,0,0,0.05);
    height: 70px;
  }
  body{
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .dropdown-item:hover, .dropdown-item:focus {
    background-color: #007bff;
  }
</style>
